// import { replace } from 'lodash'
import { useAuthStore } from '~~/stores/auth'

export default () => {
  const authStore = useAuthStore()
  const { currentRoute } = useRouter()
  !authStore.intendedRoute &&
    authStore.setIntendedRoute(currentRoute.value.fullPath)
  if (process.server) {
    return navigateTo({ path: '/', query: { login: '' } })
  }
  authStore.showAuthDialog()
  try {
    return abortNavigation()
  } catch (e) {
    return navigateTo({ path: '/', query: { login: '' } })
  }
}
